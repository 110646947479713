<template>
  <v-row justify="center" align="center">
    <qr-code :text="value"></qr-code>
  </v-row>
</template>
<script>

export default {

  data() {
    return {
      value: "00020101021230810016A00000067701011201150107536000315080214KB1020571497040320API1582994752120378531690016A00000067701011301030040214KB1020571497040420API1582994752120378553037645406200.005802TH630498f6",
      size: 300,
      consumerId: "UTcAW1n8UQMGgD4WQGcNuuG5qL94Zg4B",
      consumerSecret: "ZYpoyVeZhjfkdkww",
      //   consumerId: "suDxvMLTLYsQwL1R0L9UL1m8Ceoibmcr",
      //   consumerSecret: "goOfPtGLoGxYP3DG",
      Authorization: "",
      access_token: "",
      date: "",
      value: "https://example.com",
      size: 300,
    };
  },

  created() {
    // console.log(new Date().toString())
    console.log(new Date().toISOString());

    // To create it from string
    let dateStr = "Fri Apr 20 2020 00:00:00 GMT+0530 (India Standard Time)";
    console.log(new Date(dateStr).toISOString());
  },
  methods: {
    async authorization() {
      var base64 = btoa(this.consumerId + ":" + this.consumerSecret);
      //   console.log("base64", base64);
      this.Authorization = "Basic " + base64;
      console.log("Authorization", this.Authorization);

      var axios = require("axios");
      var qs = require("qs");
      var data = qs.stringify({
        grant_type: "client_credentials",
      });
      var config = {
        method: "post",
        url: "https://openapi-sandbox.kasikornbank.com/oauth/token",
        headers: {
          Authorization: this.Authorization,
          "Content-Type": "application/x-www-form-urlencoded",
          "env-id": "OAUTH2",
          "x-test-mode": "true",
        },
        data: data,
      };

      const response = await axios(config);
      // .then(function (response) {
      //   //   console.log(JSON.stringify(response.data));
      //   console.log("response", response.data);
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      console.log("response", response);
      this.access_token = response.data.access_token;
      //   this.getQR();
    },
    async getQR() {
      console.log("this.accesstokennnnnnn", this.access_token);
      var axios = require("axios");
      var data = JSON.stringify({
        partnerId: "PTR1051673",
        partnerSecret: "d4bded59200547bc85903574a293831b",
        merchantId: "KB102057149704",
        QRType: 3,
        amount: 200,
        currencyCode: "THB",
      });

      var config = {
        method: "post",
        url: "https://openapi-sandbox.kasikornbank.com/v1/qrpayment/request",
        headers: {
          Authorization: "Bearer " + this.access_token,
          "Content-Type": "application/json",
          "x-test-mode": "true",
          "env-id": "QR002",
        },
        data: data,
      };

      const response = await axios(config);
      // .then(function (response) {
      //   console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      console.log("response222QRQRQRQ", response);
    },
    showQR() {},
  },
};
</script>